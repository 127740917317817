import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import { useAuthContext } from './useAuthContext';
export default function GuestGuard({ children }) {
    const { isAuthenticated, isInitialized } = useAuthContext();
    if (isAuthenticated) {
        return _jsx(Navigate, { to: "/dashboard" });
    }
    if (!isInitialized) {
        return _jsx(LoadingScreen, {});
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
