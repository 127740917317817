import { AbilityBuilder, Ability } from '@casl/ability';
const GetPermissions = (roles, actions) => {
    const { can, build } = new AbilityBuilder(Ability);
    if (roles) {
        roles.map((role) => {
            role.permissions.map((permission) => {
                if (permission?.action === 'all') {
                    actions?.map((action) => {
                        can(action, permission?.entity);
                    });
                }
                else {
                    can(permission?.action, permission?.entity);
                }
            });
        });
    }
    return build();
};
export default GetPermissions;
